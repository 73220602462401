.DashboardLayout {
  aside ul {
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .MuiTooltip-arrow {
    background: white;
    color: white !important;
  }
  main {
    flex-grow: 2;
  }
  .content {
    height: fit-content;
  }
  svg.stroked-icon path {
    stroke: currentColor;
  }
  svg.filled-icon path {
    fill: currentColor;
  }

  .no-border {
    border: none;
  }
  .menu-link {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 0.6rem 1rem;
    cursor: pointer;
    font-weight: 500;
    transition: all 250ms ease-in-out;

    svg path {
      fill: currentColor;
    }

    &.no-bg {
      background-color: transparent;
      //   margin: 0;
      padding: 0;
    }
  }
  .title {
    @apply text-2xl lg:text-4xl font-bold;
  }
}
