.my-table {
  &.alignLastTdRight {
    thead th:last-of-type {
      border-radius: 0 4px 4px 0;
      text-align: right;
    }
  }
  .MuiPaper-root {
    box-shadow: none;
    > div:nth-child(2) {
      gap: 1rem;
      margin: 0;
      > div {
        margin-bottom: 1rem;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 0.45rem !important;
    height: 0.45rem !important;
    @apply bg-gray-100;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 0.25rem !important;
    @apply bg-secondary;
  }
  &.hideScrollbar {
    ::-webkit-scrollbar {
      display: none !important;
    }
  }

  &.scrollX {
    .MuiPaper-root div {
      table {
        min-width: 1000px;
        // td div {
        //   width: max-content;
        // }
      }
    }
  }
  button:disabled {
    opacity: 0.4;
  }
  thead {
    th:first-of-type {
      border-radius: 4px 0 0 4px;
      // padding-left: 0;
    }
    th:last-of-type {
      border-radius: 0 4px 4px 0;
      text-align: left;
    }
    th {
      border: none;
      background-color: #f9f9fb !important;
      button.MuiButton-root {
        padding: 0;
        width: max-content;
        font-weight: 500;
        text-transform: uppercase;
        color: #8585a3;
      }
    }
  }

  td,
  th {
    font-weight: 500;
  }
  td > div {
    padding-left: 0.1rem;
  }
  .MUIDataTableFilterList-root {
    gap: 0.5rem;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  .MuiToolbar-root {
    padding: 0;
    flex-wrap: wrap;
    & > div:nth-child(2) {
      justify-content: flex-end;
      display: flex;
    }
  }
  .drop-down-action,
  .drop-down-wrapper {
    width: max-content;
    margin-left: auto;
  }

  tfoot.MuiTableFooter-root {
    td > div {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    td {
      padding: 0;
    }
    @media (max-width: 500px) {
      .MuiToolbar-root {
        justify-content: space-between;
        width: 100%;
        .MuiInputBase-root {
          margin-right: 0;
        }
        p {
          width: 50%;
        }
      }
    }
  }
}
.MuiModal-root .MuiPaper-root {
  fieldset {
    padding: 1rem;
  }
  .MuiGrid-root {
    min-width: 120px;
  }
}
