@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  max-height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  @apply font-sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
svg {
  display: inline;
}
.MuiTabPanel-root {
  padding: 24px 0 !important;
}
// utility classes
.auth-layout {
  .title {
    @apply text-2xl lg:text-4xl font-bold mb-2;
  }
}
.more-actions {
  .drop-down-content button,
  .drop-down-content a {
    display: flex;
    font-size: 14px;
    svg,
    img {
      margin-right: 0.5rem;
      width: 18px;
      height: 18px;
    }
    padding: 0;
  }
}
.btn-text {
  @apply text-primary text-sm font-medium;
}
.card {
  background: #ffffff;
  border-radius: 20px;
  &.padded {
    @apply p-10 px-6 md:px-20;
  }
}
table tr:hover {
  background-color: unset !important;
}
.piechart .apexcharts-legend-series {
  min-width: 48%;
}
.card-shadow {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
}

@media (min-width: 760px) {
  .scrollbar-style {
    &::-webkit-scrollbar {
      width: 0.375rem !important;
      height: 0.375rem !important;
      @apply bg-gray-100;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0.25rem !important;
      @apply bg-secondary;
    }
    &.white-scrollbar {
      &::-webkit-scrollbar-thumb {
        border-radius: 0.25rem !important;
        @apply bg-white;
      }
    }
  }
}
.inner {
  @apply max-w-6xl mx-auto;
  max-width: 80rem;
  width: 90%;
}
.heading-1 {
  @apply text-5xl md:text-7xl py-2;
  line-height: 5.5rem;
  &.bold {
    @apply font-bold;
  }
  @media (max-width: 760px) {
    line-height: 4rem;
  }
}
.heading-2 {
  @apply text-3xl md:text-4xl py-2;
  // line-height: 3.12rem;
}
.heading-3 {
  @apply text-3xl py-2;
  line-height: 2.5rem;
  &.bold {
    @apply font-medium;
  }
}
.heading-4 {
  @apply text-2xl py-1;
  line-height: 2rem;
  &.bold {
    @apply font-medium;
  }
}
.btn-wrap {
  @apply flex flex-col mt-8 justify-between md:flex-row space-y-4 md:space-y-0;
  .MuiButton,
  button {
    @apply w-full;
    @media (min-width: 760px) {
      width: 48%;
    }
  }
}
.form-group-flex {
  align-items: stretch;
  @apply my-4 flex justify-between flex-wrap items-center;
  column-gap: 1rem;
  .form-group-wrapper,
  .file-input {
    width: 48%;
    margin: 0;
    .form-group {
      @apply mt-8 mb-0;
      &.no-space {
        margin-top: 0;
      }
    }
    &.full-width {
      width: 100%;
    }
  }

  @media (max-width: 760px) {
    flex-wrap: wrap;
    > .form-group,
    > .select-group,
    > .file-input {
      width: 100%;
    }
  }
}
.single-input-submit {
  @apply flex flex-col md:flex-row md:space-x-2 w-full space-y-4 md:space-y-0;
  button {
    height: 48px;
  }
}

.reset-pwd-card {
  @apply flex flex-col bg-[#fff] rounded-[12px] w-full md:w-[500px] md:h-[462px] pt-[50px] px-[24px] pb-[24px] md:pt-[75px] justify-center items-center;
}

.modal-card {
  @apply flex bg-[#fff] rounded-[12px] w-full md:w-[500px] h-auto md:h-[auto]  flex-col;
}

.dashboard-h2 {
  @apply text-grey30 text-[16px] md:text-[20px] font-semibold;
}

.dashboard-mini-btn {
  @apply font-semibold text-[12px] md:text-[14px] text-primary;
}

.general-border {
  @apply border-[1px] p-[24px]  border-borderColor rounded-lg;
}

.chat-form {
  display: flex;
  margin-bottom: 4px;
  align-items: center;
  button {
    margin: 0 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form-group {
    @apply border-none mx-3 bg-none;
    &,
    * {
      background-color: transparent !important;
    }
  }
}

.text-prop {
  line-height: 150%;
  letter-spacing: -0.02em;
}

.ticket__texts {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #69698c;
}

.ticket__span {
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: -0.04em;
}

.table__texts {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #2c2c3a;
}

.noScrollBar {
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &.white-scrollbar {
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
}

.bold__text__24 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  letter-spacing: -0.04em;
  color: #53536f;
}

input:checked + .toggle__switch {
  background-color: #8937ce !important;
}

.toggle__switch,
.toggle__active {
  position: relative;
  background-color: #bfbfcf;
  border-radius: 24px;
  display: inline-block;
  padding: 4px;
  width: 40px;
  height: 24px;

  .toggler {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      height: 16px;
      width: 16px;
      border-radius: 999999px;
      background: #fff;
      left: 4px;
      bottom: 4px;
      right: 4px;
      top: 4px;
      transition: 0.25s;
    }
  }

  input[type="checkbox"] {
    display: none;
  }

  input:checked + .toggler::before {
    transform: translateX(16px);
    right: 4px;
    left: 4px;
    bottom: 4px;
  }
}

.toggle__active {
  position: relative;
  background-color: #8937ce;
  border-radius: 24px;
  display: inline-block;
  padding: 4px;
  width: 40px;
  height: 24px;
}

.message__textarea {
  resize: none;
  padding: 1rem;
  height: 150px;
  border: 1px solid #dcdce5;
  border-radius: 12px;

  &:focus,
  :hover {
    border: 1px solid #dcdce5;
    outline: none;
  }
}

.MuiTablePagination-root {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 1.5rem;
}

.error-border {
  @apply border-error focus-within:border-error;
}
