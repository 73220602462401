.empty-response-pg {
  text-align: center;
  @apply flex flex-col items-center justify-center;
  margin: 3rem auto;
  height: 100%;
  img {
    max-width: 162px;
    max-height: 168px;
  }
  p.title {
    font-size: 20px;
    font-weight: 600;
    color: #424257;
    margin: 1rem auto;
    text-align: center;
    max-width: 350px;
  }
  p.extraText {
    color: #8585a3;
  }
  .icon-wrap {
    border-radius: 50%;
    @apply flex items-center justify-center;
    svg {
      font-size: 2.5rem;
      width: 60px;
      height: 60px;
      color: #8585a3;
      path: {
        fill: #8585a3;
      }
    }
  }
  //   a {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     text-transform: unset;
  //     margin: auto;
  //     font-weight: 500;
  //     font-size: 15px;
  //     line-height: 24px;
  //     color: white;
  //     background: var(--primary);
  //     width: 157px;
  //     height: 40px;
  //     border-radius: 8px;
  //   }
}
