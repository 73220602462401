.engagement__info {
  width: 100%;
  border: 1px solid #eaeaf0;
  border-radius: 12px;
  padding-inline: 16px;
  padding-block: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .img__container {
    border-radius: 60rem;
    width: 32px;
    height: 32px;

    img {
      height: 100%;
      width: 100%;
      
    }
  }
}
